const linkResolver = () => (doc) => {
  switch (doc.type) {
    case 'basic_page':
      if (doc.id == 'W28QDygAAODGCVMS') {
        return '/contact';
      }
      if (doc.id == 'W2854igAAMfICgqo') {
        return '/equipment';
      }
      if (doc.id == 'W28F8CgAAMbICSax') {
        return '/biography';
      }
      return '/';
      break;
    case 'article':
      return `/content/news/${doc.id}/${doc.slugs[0]}`;
      break;
    case 'event':
      return `/content/concert-dates/${doc.id}/${doc.slugs[0]}`;
      break;
    case 'album':
      return `/content/recordings/${doc.id}/${doc.slugs[0]}`;
      break;
    default:
     return '';
  }
};

module.exports = { linkResolver };