import * as React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';
import { linkResolver } from '../utils/linkResolver';

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return (
    <div>
      <div className="row">
        <div className="columns">
          <p>Not a preview!</p>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <div className="row">
        <div className="columns">
          <p>Loading preview...</p>
        </div>
      </div>
    </div>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'jeanlucponty',
  linkResolver,
});